import React, { useState } from "react";
import { APIManager, APIResponse } from "../Apt";
import { useParams } from "react-router-dom";

const Unsubscribe: React.FC = () => {
  
    const { us_ref , us_code } = useParams();
  

  const updateSubscriptionLevel = async () => {
    const headers = {
        Authorization: `Bearer ${us_ref}`,
    }
    const api = new APIManager("https://identity.wajeez.com" , headers);
    
    try {
      const response: APIResponse = await api.post("/api/identity/v1/Account/update-email-subscription", {
        code: 1,
      });
    } catch {
      console.log("Error");
    }
  };

  const [unsubscribeReason, setUnsubscribeReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const reasons = [
    {
      value: "AlreadySubscribed",
      label: "اتلقى الكثير من الإيميلات",
    },
    {
      value: "DontLikeEmails",
      label: "لا أحب التواصل عبر الإيميلات",
    },
    {
      value: "AlreadyPremium",
      label: "انا مشترك بريميوم و اتلقى عروض اشتراك",
    },
    {
      value: "AppNotInterested",
      label: "غير مهتم بالتطبيق",
    },
    {
      value: "RecommendationsNotInterested",
      label: "غير مهتم بتوصيات المحتوى",
    },
    {
      value: "Other",
      label: "سبب اخر",
    },
  ];

  const handleReasonChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setUnsubscribeReason(event.target.value);
  };

  const handleOtherReasonChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOtherReason(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateSubscriptionLevel();
  };

  return (
    <body dir="rtl">
      <header className="bg-gray-800 text-white flex justify-between items-center py-4 px-6">
        <div className="flex items-center">
          <img src="wajeezlogo.png" alt="Logo" className="w-12 h-12" />
          <div className="ml-auto py-4 text-white mr-3">
            <h1 className="text-2xl text-right font-medium">وجيز</h1>
            <p className="text-sm">خدمة الرسائل الدعائية</p>
          </div>
        </div>
      </header>

      <div className="bg-white border rounded-lg px-8 py-6 mx-auto my-8 max-w-2xl">
        <h2 className="text-2xl font-medium mb-4">
          الغاء الاشتراك بالرسائل الدعائية
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="unsubscribe_reason"
              className="block text-gray-700 font-medium mb-2 text-right"
            >
              سبب الغاء الاشتراك
            </label>
            <select
              id="unsubscribe_reason"
              name="unsubscribe_reason"
              className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400 text-right"
              required
              value={unsubscribeReason}
              onChange={handleReasonChange}
            >
              <option value="">إختيار السبب</option>
              {reasons.map((reason) => (
                <option key={reason.value} value={reason.value}>
                  {reason.label}
                </option>
              ))}
            </select>
          </div>

          {unsubscribeReason === "Other" && (
            <div className="mb-4">
              <label
                htmlFor="other_reason"
                className="block text-gray-700 font-medium mb-2 text-right"
              >
                سبب اخر
              </label>
              <textarea
                id="other_reason"
                name="other_reason"
                className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-teal-400"
                value={otherReason}
                onChange={handleOtherReasonChange}
              ></textarea>
            </div>
          )}

          <div>
            <button
              type="submit"
              className="bg-teal-700 text-white px-4 py-2 rounded-lg hover:bg-teal-600"
            >
              إرسال
            </button>
          </div>
        </form>
      </div>
    </body>
  );
};

export default Unsubscribe;
