import React, { useEffect, useState } from "react";
import { APIManager, APIResponse } from "../Apt";
import { useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";

const ConfirmThankyou: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const us_ref = searchParams.get("us_ref");
  const us_code = searchParams.get("us_code");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const confirmEmailUsingTokenAndCode = async () => {
    setIsLoading(true);
    const headers = new Headers(
    {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + us_ref,
    }
    );
    const api = new APIManager("https://identity.wajeez.com", headers);

    const response: APIResponse = await api.post(
      "/api/identity/v1/Account/email-validation",
      {
        code: `${us_code}`,
      });
      
    if(response.status === 200){
      setIsLoading(false);
      setIsError(false);
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    confirmEmailUsingTokenAndCode();
  }, []);

  const renderLoading = () => (
    <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 ml-4 border-teal-500"></div>
    <p className="ml-4 text-xl font-semibold">جاري التحقق...</p>
  </div>
  );

  const renderError = () => (
    <div className="flex flex-col items-center">
      <div className="w-16 h-16 bg-red-500 rounded-full flex items-center justify-center">
        <div className="flex text-center">
        <ReactSVG className="mb-32" src="sad_feelings.svg"  
         beforeInjection={(svg) => {
          svg.classList.add('svg-class-name')
          svg.setAttribute('style', 'width: 300px')
        }}
        
        />
        </div>
      
      </div>
      <p className="mt-4 text-2xl font-bold text-red-500 mb-2">حدث خطأ</p>
      <p className="text-xl text-center mb-4">
        حدث خطأ أثناء تأكيد البريد الإلكتروني الخاص بك. يرجى المحاولة مرة أخرى لاحقًا.
      </p>
    </div>
  );
  

  return (
    <body dir="rtl">
      <header className="bg-gray-800 text-white flex justify-between items-center py-4 px-6">
        <div className="flex items-center">
          <img src="wajeezlogo.png" alt="Logo" className="w-12 h-12" />
          <div className="ml-auto py-4 text-white mr-3">
            <h1 className="text-2xl text-right font-medium">وجيز</h1>
            <p className="text-sm">خدمة التحقق من الإيميل</p>
          </div>
        </div>
      </header>

      <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
        {isLoading ? (
          renderLoading()
        ) : isError ? (
          renderError()
        ) : (
          <>
          <div className="p-4 rounded-full bg-teal-300 mb-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-7-7a1 1 0 011.414-1.414L9 13.586l7.293-7.293a1 1 0 011.414 1.414l-8 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h1 className="text-3xl font-bold mb-2">شكرا لك!</h1>
        <p className="text-xl text-center mb-4">تم تأكيد الإيميل الخاص بك .</p>
        <p className="text-lg text-gray-600 mb-8">🎉👍🎊</p>
        <a
          href="/"
          className="px-4 py-2 rounded-full bg-teal-500 text-white hover:bg-teal-600 transition-all duration-300 ease-in-out"
        >
          تصفح التطبيق الان
        </a>
        </>
        )}
        
        
      </div>
    </body>
  );
};

export default ConfirmThankyou;
