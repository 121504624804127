export interface APIResponse<T = any> {
    data: T;
    status: number;
  }
  
  export interface APIError {
    message: string;
    status?: number;
    data?: any;
  }
  
  export class APIManager {
    private baseURL: string;
    private headers?: Headers;
  
    constructor(baseURL: string, headers?: any) {
      this.baseURL = baseURL;
      this.headers = headers ? new Headers(headers) : undefined;
    }
  
    public async get<T>(
      url: string,
      options?: RequestInit
    ): Promise<APIResponse<T>> {
      try {
        const response: Response = await fetch(`${this.baseURL}${url}`, {
          method: "GET",
          headers: this.headers,
          ...options,
        });
        return {
          data: await response.json(),
          status: response.status,
        };
      } catch (error) {
        throw this.handleAPIError(error);
      }
    }
  
    public async post<T>(
      url: string,
      data?: any,
      options?: RequestInit
    ): Promise<APIResponse<T>> {
        console.log(this.headers);
      try {
        const response: Response = await fetch(`${this.baseURL}${url}`, {
          method: "POST",
          headers: this.headers,
          body: JSON.stringify(data),
          ...options,
        });
        return {
          data: await response.json(),
          status: response.status,
        };
      } catch (error) {
        throw this.handleAPIError(error);
      }
    }
  
    public async put<T>(
      url: string,
      data?: any,
      options?: RequestInit
    ): Promise<APIResponse<T>> {
      try {
        const response: Response = await fetch(`${this.baseURL}${url}`, {
          method: "PUT",
          headers: this.headers,
          body: JSON.stringify(data),
          ...options,
        });
        return {
          data: await response.json(),
          status: response.status,
        };
      } catch (error) {
        throw this.handleAPIError(error);
      }
    }
  
    public async delete<T>(
      url: string,
      options?: RequestInit
    ): Promise<APIResponse<T>> {
      try {
        const response: Response = await fetch(`${this.baseURL}${url}`, {
          method: "DELETE",
          headers: this.headers,
          ...options,
        });
        return {
          data: await response.json(),
          status: response.status,
        };
      } catch (error) {
        throw this.handleAPIError(error);
      }
    }
  
    private handleAPIError(error: any): APIError {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { status, data } = error.response;
        return {
          message: `API Error: ${status} - ${data}`,
          status,
          data,
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          message: "API Error: No response received",
        };
      } else {
        // Something happened in setting up the request that triggered an Error
        return {
          message: `API Error: ${error.message}`,
        };
      }
    }
  }
  