

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Unsubscribe from './pages/Unsubscribe';
import ConfirmThankyou from './pages/ConfirmThankyou';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/unsubscribe" element={<Unsubscribe/>} />
      <Route path="/confirm" element={<ConfirmThankyou/>} />
      </Routes>
     
      </BrowserRouter>
      </div>
  );
}

export default App;
